/* mise en place des polices */ 

.sect__txt,
.scroll__txt{
    font-size: 17px;
    line-height: 160%;
    font-family: 'Arvo', serif;
    width: 30ch;
    font-weight: 400;
    color: #001E0E;
    &--client{
        width: 30ch;
        margin: 14px 0 30px 0 ; 
    }
    &--info{
        width: 28ch;

    }
    &--rue{
        width: 25ch;
        padding: 0 0 30px 0;
    }
    &--contact{
        
    }
    &--oswald{
        font-family: 'Oswald', sans-serif;
        font-weight: 600;
    }
    
    &--ecart2{
        margin: 40px 0;
        width: 30ch;
    }
    &--input{
        width: 15ch;
    }
}

.sect__titre{
    font-family: 'Oswald', sans-serif;
    font-size: 44px;
    font-weight: 900;
    color: #001E0E;
    &--2{
        font-family: 'Oswald', sans-serif;
        font-size: 31px;
        font-weight: 900;
        color: #001E0E;
    }
    &--3{
        color: #001E0E;
        font-weight: 600;
        font-family: 'Oswald', sans-serif;
        font-size: 22px;
    }
    &--4{
        color: #001E0E;
        font-weight: 400;
        font-family: 'Oswald', sans-serif;
        font-size: 17px;
        margin: 45px 0 30px 0;
    }
    &--court{
        width: 15ch;
    }
    &--ecart{
        margin: 34px 0 23px 40px;
        padding: 60px 0 0 0 ;
    }
    &--ecart2{
        margin: 40px 0 -10px 0;
    }
}
.nav__lien{
    font-family: 'Oswald', sans-serif;
    font-size: 18px;
    font-weight: 900;
    letter-spacing: 0;
}
.logo__nom{
    font-size: 18px;
    font-weight: 900;
    font-family: 'Oswald', sans-serif;
}
.sect__lien{
    font-family: 'Oswald', sans-serif;
    font-weight: 900;
    text-transform: uppercase;
    text-decoration: none;
    color: #001E0E;
}
.scroll{
    &__titre{
        font-family: 'Oswald', sans-serif;
        font-weight: 500;
        font-size: 22px;
    }
    &__txt{
        width: 25ch;
        padding: 0 0 30px 0;
    }
    &__lien{
        font-family: 'Oswald', sans-serif;
        font-size: 17px;
        color: #F4F9F6;
        text-decoration: none;
    }
    
}
.complement{
    &__titre{
        margin: 0 40px;
    }
    &__txt{
        line-height: 160%;
        font-family: 'Arvo', serif;
        font-size: 14px;
        width: 15ch;
    }
    &__lien{
        color: #F4F9F6;
        text-decoration: none;
        text-transform: uppercase;
        font-family: 'Oswald', sans-serif;
        font-size: 17px;
    }
}
.foot{
    &__txt{
        display: block;
        &--end{
            font-family: 'Arvo', serif;
            font-size: 14px;
            letter-spacing: 0.5px;
        }
    }
    &__btn{
        text-decoration: none;
        text-transform: uppercase;
        font-family: 'Oswald', sans-serif;
        font-size: 17px;
    }
}
    

@media(min-width:650px){
    .sect{

        &__txt{

            &--rue{
                width: 25ch;
                padding: 0 0 30px 18vw;
            }
            &--contact{
                width: 30ch;
                margin: 0 0 0 0vw;
            }
            &--ecart2{
                width: 32ch;
            }
            &--ecart3{
                margin: 20px 0 0 0 ;
                padding: 120px 0;
            }
            &--oswald{
                padding: 60px 0 0 0 ;
            }
        }

        &__titre{

            &--ecart{
                margin: 34px 0 23px 90px;
            }
            &--ecart3{
                padding: 0 0 22px 0;
            }
            &--4{
                margin: 0;
            }
        }
    }
}

@media(min-width:1080px){
    .sect{

        &__txt{
            &--rue{
                width: 25ch;
                padding: 30px 0 30px 0vw;
            }
            &--ecart3{
                padding: 20px 0;
            }

        }

        &__titre{
            margin: 45px 0 45px 0 ;
            width: 10ch;
            font-size: 88px;
            &--2{
                margin: 45px 0 45px 110px ;
                font-size: 55px;
                width: 30ch;
            }
            &--3{
               /* margin: 45px 0 45px 110px ;*/
                font-size: 32px;
                width: 25ch;
            }
            &--4{
                margin: 45px 0 45px 0 ;
                font-size: 17px;
                width: 25ch;
            }
            &--ecart{
                margin: 120px 0 23px 10vw;
            }
            &--big{
                width: 25ch;
                margin: 120px 0 45px 0;

            }
            
        }
    }
    .scroll{
        &__txt{
            padding: 0 0 30px 10px;
        }
        &__lien{
            margin: 0 0 0 15px;
        }
        
    }
    .nav{
        &__lien{
            color: #001E0E;
            padding: 5px 10px ;
            font-family: 'Oswald', sans-serif;
            font-size: 17px;

        }
    }
}

@media (min-width:1600px){

    .sect{
  
        &__titre{
            
            &--2{
                margin:45px 0 45px 15vw ;
            }
            &--ecart{
                margin: 120px 0 23px 25vw;
            }
        }
    }

    .complement{
        &__titre{
            margin: 0 0 0 10vw;
        }
    }
  }