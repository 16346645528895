@use 'normalize.css';
@use 'navigation.scss';
@use 'layout.scss';
@use 'text.scss';

body{
  overflow-x: hidden;
}

.sect{
  margin: 86px 40px;
  &--first{
    margin: 180px 40px 86px 40px;
  }
  &--backColor{
    background-color: #FEEAC2;
    width: 100%;
    height: 60vh;
    margin: -50px 0 0 0 ;
  }
  &--complement{
    background-color: #FEEAC2;
    width: 100vw;
    height: 250vh;
    padding: 40px 0;
  }
  &--padding{
    padding: 0;
  }
  &--padding2{
    padding: 60px 0px 0 20px;
  }
  
  &__lien{
    
    padding: 10px 25vw 10px 15px;
    border-radius: 20px;
    width: 30vw;
    height: 20px;
    background-color:#EBD2B4 ;
    margin: 15px 0 45px 0;
    cursor: pointer;
      &--ecart{
        position: relative;
        top: 20px;
      }
      &--saut{
        display: block;
        margin: 22px 0;
        width: 100px;
      }
      &--complement{
        margin: 20px 0;
        position: relative;
        top: 24px;
        
      }
      &--width{
        width: 200px;
        padding: 10px 60px 10px 20px;
        text-align: center;
      }
  }
  &__liste{
    list-style: none;
    display: flex;
    flex-direction: column;
  }
  &__img{
   background-position: center;
   background-size: cover;
   background-repeat: no-repeat;
    &--haute{
      width: 100% ;
      height: 70vh;
      margin: -25px 0 35px 0;
    }
    &--big{
      height: 50vh;
      width: 100%;
      image-rendering: pixelated;
    }
    &--client{
      width: 100% ;
      height: 70vh;

    }
    &--atelier{
      width: 100vw;
      height: 50vh;
      margin: 24px 0 22px -40px;
    }
    &--atelier2{
      width: 100vw;
      height: 60vh;
      margin: 0 0 0 -40px;
      background-position: center;
      background-size: contain;
    }
    &--atelier3{
      width: 80vw;
      height: 50vh;
      border-radius: 20px;
    }
    &--atelier4{
      width: 100vw;
      height: 50vh;
      margin: 24px 0 22px -20px;
    }
    &--complement{
      width: 70%;
      height: 40vh;
      border-radius: 15px;
      margin: 0 0 20px 10vw;
    }
  }
  &__box{
    &--reverse{
      margin: 146px 0 ;
    }
    &--complement{
      margin: 46px 40px 120px 40px  ;
    }
  }
  &__scroll{
    position: relative;
    top: 60px;
    width: 100vw;
    margin: 0 0 146px -40px;
  }
  &__element{
    margin: 18px 0  18px 5vw;
    width: 180px;
    padding: 0;
  }
  
}
.scroll{
  
    &__list{
      display: flex;
      flex-direction: row;
      list-style: none;
      overflow: hidden;
      overflow-x:scroll;
      scroll-snap-type: x mandatory;
      scroll-behavior: smooth;
      transition: 0.6s;

    }
    &__element{
      padding: 20px;
      margin:  0 20px;
      scroll-snap-align: start;
      background-color: #FEEAC2;
      height: 80vh;
      width: 320px;
      border-radius: 30px;
    }
    &__img{
      height: 40vh;
      width: 68vw;
      border-radius: 15px;
      margin: -60px 0 20px 0;
    }
    &__titre{
      position: relative;
      top: 60px;
      left: 180px;
      width: 100px;
      background-color: #EBD2B4;
      padding: 10px 50px 10px 20px;
      border-radius: 20px;
      box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
      transform: rotate(-90deg);
    }
    &__lien{
      background-color: #0C4728;
      border-radius: 15px;
      padding: 5px 40px 5px 10px;
      margin: 30px 0;
      cursor: pointer;
    }
    &__change{
      display: none;
    }
}

.rs{
  &__list{
    list-style: none;
    display: flex;
    flex-direction: row;
    margin: 42px 0 22px -50px;
    
  }
  &__element{
    background-color: #EBD2B4;
    width: 50px;
    height: 50px;
    border-radius: 50px;
    padding: 5px;
    display: flex;
    margin: 0 20px;
    
  }
   &__icon{
    width:  40px;
    padding: 5px 0 0 5px;
    
  }
  &__lien{
    width: 80%;
    cursor: pointer;
  }
}
.fiche{
  &__atelier{
    background-color: #FEEAC2;
    width: 100%;
    height: 90vh;
    padding: 20px 20px 40px 20px;
    margin: 40px 0 40px -20px;
    border-radius: 30px;
  }
}
.complement{

  &--flex{
    margin: 32px 0;
  }
  &__box{
    box-shadow:5px 4px 38px rgba(0, 0, 0, 0.1);
    width: 70vw;
    height: 50vh;
    border-radius: 20px;
    margin: 15px 40px 15px 10px;
    padding: 30px 10px 40px 30px  ;
  }
  &__lien{
    padding: 10px 80px 10px 10px ;
    background-color: #0C4728;
    border-radius: 15px;
    position: relative;
    top: 24px;
    cursor: pointer;
    &--float{
      position: relative;
      top: 50vh;
      left: 20vw;
    }
  }
  &__liste{
    list-style: none;
    margin: -20px 0 0 0;
    padding: 0 0 0 20px;
  }
  &__info{
    margin: 20px 0;
    &--ecart{
      padding: 10px 0  0 0;
    }
  }
}

.foot{

  &__rs{
    margin: 60px 40px ;
  }
  &__info{
    margin: 60px 40px ;
  }
  &__box{
    display: flex;
    flex-direction: row;
  }
  &__contact{
    margin: 84px 40px ;
  }
  &__input{
    background-color: #FEEAC2;
    width: 100%;
    height: 40px;
    border-radius: 20px;
    border: none;
    text-align: left;
    padding: 0 20px;
    margin: 10px 0 30px 0;
    &--txt{
      height: 160px;
      text-align: none;
    }
  }
  &__btn{
    background-color: #EBD2B4;
    border-radius: 20px;
    color: #0C4728;
    padding: 5px 80px;
    width: 200px;
    margin: 0 0 0 15vw;
    display: flex;
    align-content: center;
    justify-content: center;
    border: none;
    cursor: pointer;
  }
  &__color{
    background-color: #0C4728;
    height: 40px;
    width: 100%;
    color: #fff;
    text-align: center;
    display: flex;
    justify-content: center;
  }
}
.rs{
  &__list{
    display: flex;
    flex-direction: column;
    &--hori{
      flex-direction: row;
      margin: 32px 0 10px -10vw;
    }
  }
  &__element{
    margin: 10px ;
  }
  &__lien{
    display: flex;
    flex-direction: row;
    text-decoration: none;

  }
  &__icon{
    margin: 20px ;
    height: 40px;
    width: 40px;
    position: relative;
    top: -20px;
    left: -20px;
  }
}

.input{
  &__list{
    display: flex;
    flex-direction: column;
    list-style: none;
    padding: 20px ;
    
  }
  &__element{
    background-color: #FEEAC2;
    border-radius:20px ;
    width: 80vw;
    height: 40vh;
    margin: 20px 0 20px -20px;
    padding: 10px;
    cursor: pointer;
  }
  &__radio{
    border: red 5px solid;
    margin: 10px 0 0px 35vw;
    height: 25px;
    width:  25px;
    cursor: pointer;
  }
  &__box{
    &--flex{
      margin: -30px 0 0 60px;
    }
    &--ecart{
      margin: 30px 0 0 60px;

    }
  }
}



@media (min-width:600px){
  .sect{
    &--padding{
      padding: 60px 0 32px 0vw;
    }
    &--padding2{
      padding: 60px 0px 0 10vw;
    }
    &--complement{
      height: 210vh;
      padding: 80px 0;
    }
    
    &--backColor{
      background-color: #FEEAC2;
      width: 100%;
      height: 40vh;
      margin: -60px 0 0 0 ;
    }
    &__liste{
      display: flex;
      flex-direction: row;
    }
    &__lien{
      padding: 10px 5vw 10px 15px;
      border-radius: 20px;
      width: 170px;
      height: 20px;
      background-color:#EBD2B4 ;
      margin: 15px 0 45px 0;
      &--ecart{
        padding: 10px 20vw 10px 15px;
      }
      &--ecart2{
        margin: 0 20vw;
      }
      &--ecart3{
        margin: 30px 0;
      }
    }
    &__img{


      &--big{
        width: 75vw;
        height: 35vh;
        border-radius: 15px;
        margin: 0 auto 0 90px;
      }
      &--haute{
        width: 40vw ;
        height: 50vh;
        margin: 25px 0 35px 10px;
      }
      &--client{
        width: 40vw ;
        height: 50vh;
      }
      &--atelier{
        height: 40vh;
      }
      &--ecart{
        width: 80vw;
        margin: 2vw;
      }
      &--complement{
        height: 30vh;
        width: 30vh;
        margin: 44px 0 0 20vw ;
      }
      &--atelier3{
        width: 40vw;
        height: 40vh;
        border-radius: 20px;
      }
      &--atelier4{
        width: 80vw;
        height: 40vh;
        border-radius: 20px;
        background-size: contain;
      }
    }
    &__box{
      &--ecart{
        margin: 22px 0 22px 20vw;
      }
    }
    &__scroll{
      top: 120px;
      width: 100vw;
      margin: 0 0 246px -40px;
    }
    &__input{
      width: 45vw;
    }
  }

  .input{
    &__element{
      width: 40vw;
      height: 22vh;
    }
  }
  .scroll{
    &__element{
      margin:  0 40px;
      height: 60vh;
      width: 640px;
    }
    &__img{
      height: 32vh;
      width: 50vw;
      border-radius: 15px;
      margin: -80px 0 20px 0;
      background-size: cover;
    }
    &__titre{
      top: 40px;
      left: 280px;
    }
    &__lien{
      background-color: #0C4728;
      border-radius: 15px;
      padding: 8px 90px 8px 10px;
      margin: 30px 0;

    }
    &__change{
      display: none;
    }
    
  }
  .rs{
    &__list{
      &--hori{
        flex-direction: row;
        margin: 12px 0 10px 14vw;
      }
    }
  }
  .fiche{
    &__atelier{
      width: 100%;
      height: 40vh;
      padding: 20px 20px 40px 20px;
      margin: 40px 0 40px -20px;
    }
  }
  .complement{
    &--flex{
      margin: 62px 0;
    }
    &__box{
      width: 70vw;
      height: 40vh;
      border-radius: 20px;
      margin: 60px 40px 60px 10px;
      padding: 30px 0px 40px 40px  ;
    }
    &__lien{
      padding: 10px 80px 10px 10px ;
      background-color: #0C4728;
      border-radius: 15px;
      position: relative;
      top: 24px;
      &--float{
        position: relative;
        top: 45vh;
        left: -70vw;
      }
    }
    &__liste{
      list-style: none;
      margin: -40px 0 0px 15vw;
      padding: 0 0 0 20px;
     
    }
    &__info{
      margin: 20px 0;
      &--ecart{
        padding: 10px 0  0 0;
      }
    }
  }
  .foot{
    &__box{
      display: flex;
      flex-direction: row;
      width: 50%;
      margin: 0 0 0 18vw;
    }
    &__input{
      background-color: #FEEAC2;
      width: 350px;
      margin: 10px 0 30px 0;
    }
    &__contact{
      margin: 84px 20vw ;
    }
    &__btn{
      margin: 20px 0 0 -8vw;
      width: 350px;
    }
  }
}





@media (min-width:1000px){
  .sect{
    margin: 220px 40px ;
    &--padding{
      padding: 60px 0 32px 0vw;
    }
    &--padding2{
      padding: 60px 0px 0 10vw;
    }
    &--complement{
      height: 210vh;
      padding: 80px 0;
    }
    &--atelier{
      display: flex;
      flex-direction: row;
    }
    &--atelierPC{
      display: flex;
      flex-direction: column;
    }
    &--ecartForm{
      margin: 220px 0 220px 10vw;
    }
    &--first{

    }
    &--backColor{
      background-color: #FEEAC2;
      width: 100%;
      height: 70vh;
      margin: -120px 0 0 0 ;
    }
    &__liste{
      display: flex;
      flex-direction: row;
    }
    &__lien{
      padding: 10px 5vw 10px 15px;
      border-radius: 20px;
      width: 170px;
      height: 20px;
      background-color:#EBD2B4 ;
      margin: 15px 0 45px 0;
      &--ecart{
        padding: 10px 5vw 10px 15px;
      }
      &--ecart2{
        margin: 0 20vw;
      }
      &--ecart3{
        margin: 30px 0;
      }
    }
    &__img{


      &--big{
        width: 80vw;
        height: 60vh;
        border-radius: 15px;
        margin: 0 auto 0 10vw;
      
      }
      &--haute{
        width: 40vw ;
        height: 90vh;
        margin: 25px 0 35px 10px;
      }
      &--client{
        width: 38vw ;
        height: 75vh;
        border-radius: 15px;
      }
      &--atelier{
        height: 90vh;
        width: 40vw;
      }
      &--atelierPC{
        height: 500px;
        width: 760px;
      }
      &--atelier2{
        width: 50vw;
        height:50vh;
        margin: 60px 0 0 -10vw;
        background-position: center;
        background-size: contain;
      }
      &--ecart{
        width: 700px;
        height: 515px;
        margin: 2vw;
        
      }
      &--ecartsimple{
        margin: 120px 0 0 0;
      }
      &--complement{
        height: 460px;
        width: 460px;
        margin: 44px 0 0 300px ;
      }
      &--atelier3{
        width: 40vw;
        height: 40vh;
        border-radius: 20px;
        margin: 15px 0 15px -10vw;
      }
      &--atelier4{
        width: 80vw;
        height: 60vh;
        border-radius: 20px;
        background-size: contain;
        padding: 0;
        margin: 0 0 0 10vw;
      }
    }
    &__box{
      &--complement{
        display: flex;
        flex-direction: row;
      }
      &--ecart{
        margin: 22px 0 22px 2vw;
      }
    }
    &__scroll{
      top: 0;
      left: 40px;
      width: 100vw;
      margin: 0 0 246px -40px;
    }
    &__input{
      width: 45vw;
    }
    &__complement{
      padding: 60px 0 0 0 ;
    }
  }

  .input{
    &__element{
      width: 40vw;
      height: 22vh;
    }
    
  }
  .scroll{
    &__element{
      margin:  0 40px;
      height: 700px;
      width: 550px;
    }
    &__img{
      height: 390px;
      width: 420px;
      border-radius: 15px;
      margin: -80px 40px 20px 10px;
      background-size: cover;
    }
    &__titre{
      top: 40px;
      left: 340px;
    }
    &__lien{
      background-color: #0C4728;
      border-radius: 15px;
      padding: 8px 90px 8px 10px;
      margin: 30px 0;

    }
    &__change{
      display: none;
    }
    
  }
  .rs{
    &__list{
      &--hori{
        flex-direction: row;
        margin: 12px 0 10px 14vw;
      }
    }
  }
  .fiche{
    &__atelier{
      width: 82%;
      height: 45vh;
      padding: 20px 20px 40px 20px;
      margin: 40px 0 40px 10vw;
    }
  }
  .complement{
    &--flex{
      margin: 0 62px;
      display: flex;
      flex-direction: row;
    }
    &__box{
      width: 650px;
      height: 425px;
      border-radius: 20px;
      margin: 60px 40px 60px 10px;
      padding: 30px 0px 40px 40px  ;
    }
    &__lien{
      padding: 10px 80px 10px 10px ;
      background-color: #0C4728;
      border-radius: 15px;
      position: relative;
      top: 64px;
      &--float{
        position: relative;
        top: -20px;
        left: -67vw;
      }
    }
    &__liste{
      list-style: none;
      margin: -40px 0 20px 100px;
      padding: 0 20px 0 0;
     
    }
    &__info{
      margin: 20px 0;
      &--ecart{
        padding: 10px 0  0 0;
      }
    }
  }
  .foot{
    &__box{
      display: flex;
      flex-direction: row;
      width: 50%;
      margin: 0 0 0 0;
    }
    &__input{
      background-color: #FEEAC2;
      width: 350px;
      margin: 10px 0 30px 0;
    }
    &__contact{
      margin: 84px 0;
    }
    &__btn{
      margin: 20px 0 0 0vw;
      width: 350px;
    }
  }
}


@media (min-width:1600px){

  .sect{
    &--complement{
      height: 160vh;
      padding: 80px 0;
    }
    &__lien{

      &--saut{
        margin: 44px 0;
      }
    }
    &__scroll{
      position: relative;
      top: 120px;
      width: 100vw;
      margin: 0 0 240px -40px;
    }
    &__img{

      &--big{
        width: 1000px;
        height: 550px;
        margin: 0 0 0 25vw;
      }
      &--haute{
        width: 560px;
        height: 828px;
      }
      &--client{
        width: 460px;
        height: 638px;
      }
      &--atelier{
        width: 660px;
        height: 968px;
      }
      &--atelier3{
        width: 340px;
        height: 264px;
        margin: 20px -190px 20px 0;
      }
      &--map{
        width: 700px;
        height: 515px;
      }
      &--atelierPC{
        width: 760px;
        height: 506px;
      }
    }
    
  }
  .complement{
      &--flex{
        margin: 0 0 0 10vw;
        display: flex;
        flex-direction: row;
      }
    &__lien{
      padding: 10px 80px 10px 10px ;
      background-color: #0C4728;
      border-radius: 15px;
      position: relative;
      top: 64px;
      &--float{
        position: relative;
        top: -40px;
        left: -52vw;
      }
    }
  }
  .fiche{
    &__atelier{
      width: 1160px;
      height: 308px;
      padding: 20px 20px 40px 20px;
      margin: 40px 0 40px 20vw;
    }
  }
}