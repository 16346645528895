

.head{
    width: 100%;
    height: 60px;
    background-color: #FEEAC2;
    display: flex;
    flex-direction: row;

    &__logo{
        position: relative;
        top: 5px;
        left: 32px;
        display: flex;
        flex-direction: row;
        width: 50%;
    }
    
}
.logo{
    &__img{
        width: 51px;
        height: 51px;
        
    }
    &__nom{
        margin: 18px 0 0 5px;
    }
}
.nav{
    &__liste{
        list-style: none;
        display: none;
    }
    &__img{
        position: relative;
        top: 10px;
        display: block;
        left: 35vw;
    }
    &__img2{
        display: none;
    }
}

@media (min-width:700px){
    .head{
        display: flex;
        flex-direction: row;
        &__logo{
            width: 20%;
        }
        
    }
    .nav{
        &__img{
            display: none;
        }
        &__liste{
            display: flex;
            flex-direction: row;
            margin:30px 0 0 30vw;
        }
        &__element{
            margin: 0 33px;
            &--actif::after{
                content: "";
                display: block;
                background-color: #0C4728;
                width: 20px;
                height: 20px;
                border-radius: 20px;
                position: relative;
                top: 2px;
                left: -10px;
            }
        }
        &__lien{
            text-decoration: none;
        }
    }
}

body[data-navMenu] .head{
    background-color: #FEEAC2;
    height: 100vh;
    width: 100vw;
    display: block;
    position: relative;
    top: 0;
    left: 0;
    z-index: 99999;
    transition: 0.2s all ease-in;

}

body[data-navMenu] .nav__img{
    animation: menuclose 0.3 linear;
    display: none;

}
body[data-navMenu] .nav__img2{
    display: block;
    position: relative;
    top: -40px;
    left: 85%;
}

body[data-navMenu] .nav{
    &__liste{
        display: flex;
        flex-direction: column;
        margin: 100px auto 0 25vw;
        transition: 0.2s all ease-in;
        animation: menuAnim 0.6s linear;
    }
    &__element{
        padding: 20px 25px;
        margin: 10px 0 ;

        &--actif::after{
            content: "";
            display: block;
            background-color: #0C4728;
            width: 20px;
            height: 20px;
            border-radius: 20px;
            position: relative;
            top: 2px;
            left: -20px;
            padding: 0;
        }
    }
    &__lien{
        color: #001E0E;
        text-decoration: none;
        font-size: 22px;
    }
}


@keyframes menuAnim{
    0%{
        opacity: 0;

    }
    60%{
        opacity: 0;

    }
    100%{
        opacity: 1;
    }
}

@keyframes menuclose{
    0%{
        opacity: 1;
    }
    50%{
        opacity: 0.8;
        transform: rotate(360deg);
    }
    100%{
        opacity: 0;
    }
}

