



.sect{
    &--grid{
        display: flex;
        flex-direction: column;
    }
}
.im{
    grid-area: im;
}
.t1{
    grid-area: t1;
}
.t2{
    grid-area: t2;
}
.t3{
    grid-area: t3;
}
.t4{
    grid-area: t4;
}
.p1{
    grid-area: p1;
}
.p2{
    grid-area: p2;
}
.p3{
    grid-area: p3;
}
.p4{
    grid-area: p4;
}
.l1{
    grid-area: l1;
}
.l2{
    grid-area: l2;
}
@media(min-width:650px){
     

    .sect{
        &--flexInput{
            margin: 32px 0 0 -20px;
            display: grid;
            width: 90vw;
            grid-template-columns: repeat(6, 1fr);
            column-gap: 20px;
            grid-template-rows: auto;
            grid-template-areas: 
            "p1 p1 p1 p2 p2 p2"
            ".  .  l1 l1 .  .";
        }
        &--grid{
            margin: 0 120px;
            display: grid;
            width: 90vw;
            grid-template-columns: repeat(6, 1fr);
            column-gap: 20px;
            grid-template-rows: auto;
            grid-template-areas: 
            ".  .   . t1 t1 t1 "
            "im im  . p1 p1 p1 "
            "im im  . p2 p2 p2";
        }

        &__box{
            display: grid;
            width: 90vw;
            grid-template-columns: repeat(6, 1fr);
            column-gap: 20px;
            grid-template-rows: auto;
            grid-template-areas: 
            ".  .   . t2 t2 t2 "
            "im im  . p1 p1 p1 "
            "im im  . l1 l1 l1 "
            "im im  . t3 t3 t3 "
            "im im  . p2 p2 p2 ";

            &--pad{
                display: grid;
                width: 90vw;
                grid-template-columns: repeat(6, 1fr);
                column-gap: 20px;
                grid-template-rows: 200px 260px;
                grid-template-areas: 
                "p1 p1 p1 p3 p3 p3 "
                "l1 l1 l1 p4 p4 p4 "
                "im im im im im im";
            }
            &--reverse{
                grid-template-areas: 
                "t2 t2 t2 .   . .  "
                "p1 p1 p1 im im .  "
                "l1 l1 l1 im im .  "
                "t3 t3 t3 im im .  "
                "p2 p2 p2 im im .  ";
            }
            &--flex{
                display: grid;
                width: 90vw;
                grid-template-columns: repeat(6, 1fr);
                column-gap: 20px;
                grid-template-rows: auto;
                grid-template-areas:
                
                "p1 p1 p1 p1 .  . "
                "p2 p2 p2 p2 .  . "
                "p3 p3 p3 p3 .  . "
                "l1 l1 l1 l2 l2 l2 "
                ; 
            }
            &--flex2{
                width: 50vw;
            }
            &--complement{
                display: flex;
                flex-direction: column;
            }
        }
        &--atelier{
            display: grid;
            width: 90vw;
            grid-template-columns: repeat(6, 1fr);
            column-gap: 20px;
            grid-template-rows: auto;
            grid-template-areas: 
            "t2 t2 t2 .  .  .  "
            "im im im im im im "
            "p1 p1 p1 p1 p1 p1 ";
        }
    }
    .complement{
        &__box{
            display: grid;
            width: 90vw;
            grid-template-columns: repeat(6, 1fr);
            column-gap: 20px;
            row-gap: 10px;
            grid-template-rows: 60px 150px 80px 40px;
            grid-template-areas: 
            ".  t2 t2 t2 .  .  "
            "p1 p1 p1 p1 p1 p1 "
            "p2 p2 p2 p2 p2 p2 "
            " . l1 l1 l1 .  .  ";
        }
    }
    .fiche{
        &__atelier{
            display: grid;
            width: 90vw;
            grid-template-columns: repeat(6, 1fr);
            column-gap: 20px;
            row-gap: 10px;
            grid-template-rows: auto;
            grid-template-areas: 
            "t2 t2 t2 t2 im im"
            "p1 p1 p1 p1 im im"
            "p2 p2 p2 p2 im im";
        }
    }

}


@media (min-width:1180px){

    .sect{
        &--projetH{
            display: grid;
            width: 90vw;
            grid-template-columns: repeat(6, 1fr);
            column-gap: 20px;
            grid-template-rows: auto;
            grid-template-areas: 
            "p1 p1 p1 p1 p1 p1 "
            "p2 p2 p2 p2 p2 p2 ";
        }
        &--grid{
            margin: 0 120px;
            display: grid;
            width: 90vw;
            grid-template-columns: repeat(12, 1fr);
            column-gap: 20px;
            grid-template-rows: auto;
            grid-template-areas: 
            "im im im im im im t1 t1 t1 t1 t1 t1 "
            "im im im im im im p1 p1 p1 p1 p1 p1 "
            "im im im im im im p2 p2 p2 p2 p2 p2";
        }
        &__box{
            display: grid;
            width: 90vw;
            grid-template-columns: repeat(12, 1fr);
            column-gap: 20px;
            grid-template-rows: auto;
            grid-template-areas: 
            ".  .  .  .  .   . t2 t2 t2 t2 t2 t2"
            "im im im im im  . p1 p1 p1 p1 p1 . "
            "im im im im im  . l1 l1 l1 l1 l1 . "
            "im im im im im  . t3 t3 t3 t3 t3 . "
            "im im im im im  . p2 p2 p2 p2 p2 . ";
            &--reverse{
                grid-template-areas: 
                ". . . t2 t2 t2 t2 . .  .  .  .  "
                ". . . p1 p1 p1 . im im im im im"
                ". . . l1 l1 l1 . im im im im im"
                ". . . t3 t3 t3 . im im im im im"
                ". . . p2 p2 p2 . im im im im im";
            }
            &--pad{
                display: grid;
                width: 90vw;
                grid-template-columns: repeat(12, 1fr);
                column-gap: 20px;
                grid-template-rows: auto;
                grid-template-areas: 
                ".  p1 p1 p1 p1 p1 im im im im . . "
                ".  l1 l1 l1 l1 l1 im im im im . . "
                ".  p3 p3 p3 p3 p3 im im im im . . "
                ".  p4 p4 p4 p4 p4 im im im im . . ";
            }
            &--flex{
                display: grid;
                width: 90vw;
                grid-template-columns: repeat(12, 1fr);
                column-gap: 20px;
                grid-template-rows: auto;
                grid-template-areas:
                ". p1 p1 p1 p1 p1 im im im im im im"
                ". p2 p2 p2 p2 p2 im im im im im im"
                ". p3 p3 p3 p3 p3 im im im im im im"
                ". l1 l1 l1 l1 l1 im im im im im im"
                ; 
            }
            &--flex2{
                width: 20vw;
                height: 600px;
                padding: 0 0 0 -20vw;
            }
        }
    }
    .box{
        &__atelier{
            display: grid;
            width: 90vw;
            grid-template-columns: repeat(12, 1fr);
            column-gap: 20px;
            grid-template-rows: auto;
            grid-template-areas:
            "t2 t2 t2 t2 t2 t2 t2 t2 t2 t2 .  ."
            " . im im im im im .   . p1 p1 p1 p1"
            " . im im im im im .   . p3 p3 p3 p3"
            " . im im im im im .   . l1 l1 l1 l1"
            ; 
        }
    }
    .complement{
        &__box{
            display: grid;
            width: 90vw;
            grid-template-columns: repeat(6, 1fr);
            column-gap: 20px;
            row-gap: 10px;
            grid-template-rows: 60px 150px 80px 40px;
            grid-template-areas: 
            ".  t2 t2 t2 t2 t2 "
            ".  p1 p1 p1 .  .  "
            "p2 p2 p2 p2 p2 p2 "
            " . l1 l1 l1 .  .  ";
        }
    }
    .input{
        &__label{
            display: grid;
            width: 500px;
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: auto;
            column-gap: 20px;
            row-gap: 10px;
            grid-template-areas: 
            "p1 p2";
            
        }
    }
    .foot{

        &__grid{
            display: grid;
            width: 90vw;
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: auto;
            column-gap: 20px;
            row-gap: 10px;
            grid-template-areas: 
            "p1 p2"
            "p3 p2";
        }
    }
}


@media (min-width:1600px){
    .sect{
        &--grid{
            margin: 0 120px;
            display: grid;
            width: 90vw;
            grid-template-columns: repeat(12, 1fr);
            column-gap: 20px;
            grid-template-rows: auto;
            grid-template-areas: 
            ". im im im im im t1 t1 t1 t1 t1 t1 "
            ". im im im im im p1 p1 p1 p1 p1 p1 "
            ". im im im im im p2 p2 p2 p2 p2 p2";
        }
        &__box{
            display: grid;
            width: 90vw;
            grid-template-columns: repeat(12, 1fr);
            column-gap: 20px;
            grid-template-rows: auto;
            grid-template-areas: 
            ".  .  .  .  .   . t2 t2 t2 t2 t2 t2"
            ".  .  im im im  . p1 p1 p1 p1 p1 . "
            ".  .  im im im  . l1 l1 l1 l1 l1 . "
            ".  .  im im im  . t3 t3 t3 t3 t3 . "
            ".  .  im im im  . p2 p2 p2 p2 p2 . ";

            &--pad{
                display: grid;
                width: 90vw;
                grid-template-columns: repeat(12, 1fr);
                column-gap: 20px;
                grid-template-rows: auto;
                grid-template-areas: 
                ".  . p1 p1 p1 p1 im im im im . . "
                ".  . l1 l1 l1 l1 im im im im . . "
                ".  . p3 p3 p3 p3 im im im im . . "
                ".  . p4 p4 p4 p4 im im im im . . ";
            }

            &--reverse{
                grid-template-areas: 
                ". . . t2 t2 t2 t2 . .  .  .  .  "
                ". . . p1 p1 p1 . im im im im im"
                ". . . l1 l1 l1 . im im im im im"
                ". . . t3 t3 t3 . im im im im im"
                ". . . p2 p2 p2 . im im im im im";
            }
        }
    }
    .box{
        &__atelier{
            display: grid;
            width: 90vw;
            grid-template-columns: repeat(12, 1fr);
            column-gap: 20px;
            grid-template-rows: auto;
            grid-template-areas:
            "t2 t2 t2 t2 t2 t2 t2 t2 t2 t2 .  ."
            " . .  im im im im im  . p1 p1 p1 p1"
            " . .  im im im im im  . p3 p3 p3 p3"
            " . .  im im im im im  . l1 l1 l1 l1"
            ; 
        }
    }
    .fiche{
        &__atelier{
            display: grid;
            width: 90vw;
            grid-template-columns: repeat(6, 1fr);
            column-gap: 20px;
            row-gap: 10px;
            grid-template-rows: repeat(3 100px);
            grid-template-areas: 
            "t2 t2 t2 t2 t2 im"
            "p1 p1 p2 p2 p2 im"
            "p1 p1 p2 p2 p2 im";
        }
    }
}